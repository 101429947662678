<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Define el puesto de trabajo que deseas encontrar
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Selecciona los puestos de trabajo que más te interesen. Puedes elegir hasta 3 opciones.
      </div>
    </div>

    <ValidationObserver ref="formstepPiB" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">

      <q-form 
        ref="myFormPib"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPibReset"
        class="formDetailZone "
      >
        
        <!-- Validador general de la forma --> 
        <ValidationProvider 
          tag='div'
          :rules=" { required: true,cannaQuestPuestosMin:1, cannaQuestPuestosMax:3 }"
          :immediate="true"
          :ref="'fldx_puestosall'"
          name="puestosAll" v-slot="{ errors, invalid, validated }"
        >
          <input type="hidden" v-model="puestos_fullVals">
          <template v-if="(invalid && validated && errors[0] !==undefined)">

            <div class="notifyZone noselect notifyZoneRemove" style="margin-left: 0px;width: 100% !important;">
              <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                  {{ pib_puestosAllMsg(errors[0]) }}
              </div>
              <div style="margin-right: 14px; display:none;" >
                  <div class="closenotifyZoneIcon action_item" @click.stop="pib_doResetPuestosAll">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>
          </template>
        </ValidationProvider>

        <div class="dataSection_container">
          <div class="dataSection_title">Administrativos</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
              <template v-for="(puesto, index) in cat_Administrativo.dataCatalog">
                <div class="col-xs-12 col-md-4" :key="'puestosAdm_' + index">
                  <template v-if="puesto.id===58">

                    <q-checkbox v-model="frmData.administrativos"
                      :label="puesto.nombre" color="cannaMainColor" :val="puesto.id" 
                      @input="pib_changePuestosAdmOtros"
                    />

                    <div style="padding-left: 10px;">
                      <ValidationProvider :rules=" { 
                        required: (val_puestosAdmHasOtros===false) ? false : true, min:4, max:40, cannaTextOtros:true 
                      }"
                        :immediate="true"
                        :ref="'fldx_administrativosOtro'"
                        name="operativosOtro" v-slot="{ errors, invalid, validated  }"
                      >
                        <q-input
                          filled
                          name="operativosOtro"
                          maxlength="40"
                          v-model="frmData.administrativos_otro"
                          hint=""
                          :dense="true"
                          :label="(frmData.administrativos_otro==='' || frmData.administrativos_otro=== null)?'Por favor, especifica':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errors[0].replace('operativosOtro','Otro (descripción)') : '' "
                          :disable="(val_puestosAdmHasOtros===false)? true: false"
                        />
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-else>
                    <q-checkbox v-model="frmData.administrativos" 
                      :label="puesto.nombre" color="cannaMainColor" :val="puesto.id" 
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="dataSection_container">
          <div class="dataSection_title">Operativos</div>
          <div class="datasectionItem dataSection_separadorx">
            <div style="display:none;"> <!-- Zona para el hidden q conserva todos los valores -->
              xxxxx
            </div>
            <div class="row">
              <template v-for="(puesto, index) in cat_Operativo.dataCatalog">
                <div class="col-xs-12 col-md-4" :key="'puestosAdm_' + index">
                  <template v-if="puesto.id===37">

                    <q-checkbox v-model="frmData.operativos"
                      :label="puesto.nombre" color="cannaMainColor" :val="puesto.id" 
                      @input="pib_changePuestosOperaOtros"
                    />

                    <div style="padding-left: 10px;">
                      <ValidationProvider :rules=" { 
                        required: (val_puestosOperaHasOtros===false) ? false : true, min:4, max:40, cannaTextOtros:true 
                      }"
                        :immediate="true"
                        :ref="'fldx_operativoOtro'"
                        name="operativosOtro" v-slot="{ errors, invalid, validated  }"
                      >
                        <q-input
                          filled
                          name="operativosOtro"
                          maxlength="40"
                          v-model="frmData.operativos_otro"
                          hint=""
                          :dense="true"
                          :label="(frmData.operativos_otro==='' || frmData.operativos_otro=== null)?'Por favor, especifica':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errors[0].replace('operativosOtro','Otro (descripción)') : '' "
                          :disable="(val_puestosOperaHasOtros===false)? true: false"
                        />
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-else>
                    <q-checkbox v-model="frmData.operativos" 
                      :label="puesto.nombre" color="cannaMainColor" :val="puesto.id" 
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>


        </div>

        <!-- Validador general de la forma2 --> 
        <ValidationProvider 
          tag='div'
          :rules=" { required: true,cannaQuestPuestosMin:1, cannaQuestPuestosMax:3 }"
          :immediate="true"
          :ref="'fldx_puestosallB'"
          name="puestosAllB" v-slot="{ errors, invalid, validated }"
        >
          <input type="hidden" v-model="puestos_fullVals">
          <template v-if="(invalid && validated && errors[0] !==undefined)">
            <div class="notifyZone noselect notifyZoneRemove" style="margin-left: 0px;width: 100% !important;"
              :style="(is_Mobile === true || $q.screen.lt.sm) ? '' : 'display:none;'"
            >
              <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                  {{ pib_puestosAllMsgB(errors[0]) }}
              </div>
              <div style="margin-right: 14px; display:none;" >
                  <div class="closenotifyZoneIcon action_item" @click.stop="pib_doResetPuestosAll">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>
          </template>
        </ValidationProvider>

        <div class="ppi_pz_footer">
          <div class="rowButtons">
            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>

    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from './../../../../boot/axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpib_Data = {
  administrativos: [],
  administrativos_otro: '',
  operativos: [],
  operativos_otro: ''
}

export default {
  name: 'psi_screen_puestos',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      z_allowOpera: [],
      z_allowAdmin: [],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: {
        administrativos: [],
        administrativos_otro: '',
        operativos: [],
        operativos_otro: ''
      },
      frmData: {
        administrativos: [],
        administrativos_otro: '',
        operativos: [],
        operativos_otro: ''
      }
    }
  },
  computed: {
    cat_Operativo () { // catalogo id = 6  Puestos operativos
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(6) : this.getHelperCat(6)
      // var myorder = (this.is_Mobile === true || this.$q.screen.lt.md) ? 'orden_movil' : 'orden_web'
      // var myData =  (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(6) : this.getHelperCat(6)
      // var myDatax = JSON.parse(JSON.stringify(myData))
      // if (myDatax.dataCatalog.length>0){
      //   myDatax.dataCatalog.sort(function (a, b) {
      //     const Ao = parseInt(a[myorder])
      //     const Bo = parseInt(b[myorder])
      //     let comparison = 0
      //     comparison = (Ao > Bo) ? 1 : (Ao < Bo) ? -1 : 0
      //     return comparison
      //   })
      // }
      // return myDatax
    },
    cat_Administrativo () { // catalogo id = 7  Puestos administrativos
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(7) : this.getHelperCat(7) 
      // var myorder = (this.is_Mobile === true || this.$q.screen.lt.md) ? 'orden_movil' : 'orden_web'
      // var myData =  (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(7) : this.getHelperCat(7)
      // var myDatax = JSON.parse(JSON.stringify(myData))
      // if (myDatax.dataCatalog.length>0){
      //   myDatax.dataCatalog.sort(function (a, b) {
      //     const Ao = parseInt(a[myorder])
      //     const Bo = parseInt(b[myorder])
      //     let comparison = 0
      //     comparison = (Ao > Bo) ? 1 : (Ao < Bo) ? -1 : 0
      //     return comparison
      //   })
      // }
      // return myDatax
    },
    puestos_fullAllowVals () {
      var prueba = this.z_allowOpera.slice(0) 
      var pruebab = this.z_allowAdmin.slice(0)
      return prueba.concat(pruebab)
    },
    puestos_fullVals (){ 
      var prueba = this.frmData.administrativos.slice(0) 
      var pruebab = this.frmData.operativos.slice(0)
      return prueba.concat(pruebab)
    },
    val_puestosAdmHasOtros () {
      var test = false
      if (this.frmData.administrativos !== undefined) {
        if (this.frmData.administrativos.length) {
          test = (this.frmData.administrativos.indexOf(58) >= 0) ? true : false
        }
      }
      return test
    },
    val_puestosOperaHasOtros () {
      var test = false
      if (this.frmData.operativos !== undefined) {
        if (this.frmData.operativos.length) {
          test = (this.frmData.operativos.indexOf(37) >= 0) ? true : false
        }
      }
      return test
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiB) {
            if (this.$refs.formstepPiB.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiB.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiB.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_puestos --   test')
    },
    showLoadx ( value, type ){ // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal'
      this.$cannaDebug('-- psiPage_puestos --   do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    pib_doResetPuestosAll:function(){ 
      if (this.$refs.fldx_puestosall){
        this.$refs.fldx_puestosall.reset()
      }
      if (this.$refs.fldx_puestosallB){
        this.$refs.fldx_puestosallB.reset()
      }
    },
    pib_puestosAllMsg:function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('puestosAll','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opcion en está lista.'
      }
      //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    pib_puestosAllMsgB:function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('puestosAllB','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opcion en está lista.'
      }
      //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    pib_changePuestosAdmOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_puestos --   change Puestos AdmOtros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(58) < 0) {
        this.frmData.administrativos_otro = null
        this.$refs.fldx_administrativosOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_administrativosOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    pib_changePuestosOperaOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_puestos --   change Puestos Opera Otros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(58) < 0) {
        this.frmData.operativos_otro = null
        this.$refs.fldx_operativoOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_operativoOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    onStepFormPibReset (){ 

    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_puestos --   do Submit Data --- ')
      var test = 11 
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 

      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 && this.puestos_fullVals.length>0 ) { // Que existan valores...! 
        // Procedemos a guardar 
        test = 22

        // puestos administrativos
        if (testA._.administrativos.status === 'MODIFIED') {
          testA._.administrativos._.forEach(item => {
            if (item.status === 'ADDED') {
              if (item.current === 58) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.administrativos_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'MODIFIED') {
              toRemove.push(item.original)
              if (item.current === 58) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.administrativos_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'DELETED') {
              toRemove.push(item.original)
            }
          })
        }

        // puestos administrativos
        if (testA._.operativos.status === 'MODIFIED') {
          testA._.operativos._.forEach(item => {
            if (item.status === 'ADDED') {
              if (item.current === 37) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.operativos_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'MODIFIED') {
              toRemove.push(item.original)
              if (item.current === 37) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.operativos_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'DELETED') {
              toRemove.push(item.original)
            }
          })
        }

        if (testA._.administrativos_otro.status === 'MODIFIED') {
          var tmpArrW = toRemove.indexOf(58)
          var tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 58) // Tiene que existir
          if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
            toPatch.push([tmpArrZ[0].catalogo_id, this.frmData.administrativos_otro])
          }
        }

        if (testA._.operativos_otro.status === 'MODIFIED') {
          var tmpArrX = toRemove.indexOf(58)
          var tmpArrY = this.profileRecords.filter(item => item.catalogo_id === 37) // Tiene que existir
          if (tmpArrY.length > 0 && tmpArrX < 0) { // Solo si existe el registro ..! para aplicar en PATCH
            toPatch.push([tmpArrY[0].catalogo_id, this.frmData.operativos_otro])
          }
        }

        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')

        // ----------------------
        // Zonas a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, { 
              delimiters: [pxRC[0]],
              data: { otro: '' + pxRC[1] }
            }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData)) 
        allowNextPage = true
        
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }

      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.PUESTOS ){ // this.zstatUser.PUESTOS  = 14
        await this.hpb_updateProfileStatus( this.zstatUser.PUESTOS ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/trabajo-c'
        this.$router.push(newRuta)
      }
      return true 
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {categoria_id: '6,7', 'per-page': 'all', expand:'catalogo'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpib_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpib_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var dt_opera = [] 
        var dt_operaOtro = ''
        var dt_admin = [] 
        var dt_adminOtro = ''
        profileData.forEach(itemP => {
            if (itemP.categoriaId === 6) { //operativos 
              dt_opera.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 37){ 
                dt_operaOtro = '' + itemP.otro 
              }
            } else  if (itemP.categoriaId === 7) { //administrativos
              dt_admin.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 58){ 
                dt_adminOtro = '' + itemP.otro 
              }
            }
        })
        var newData = {
          administrativos: dt_admin,
          administrativos_otro: dt_adminOtro,
          operativos: dt_opera, 
          operativos_otro: dt_operaOtro
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpib_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpib_Data))
      }
      return true 
    }
  },
  beforeCreate () {
    // this.$cannaDebug('-- psiPage_puestos --   beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_puestos --   created')
    this.zinitScreen = false 
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_puestos --    beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_puestos --   mounted')
    this.zloadingData = true
    this.showLoadx(true, 2)

    if (this.$refs.fldx_puestosall){
      this.$refs.fldx_puestosall.reset()
    }
    if (this.$refs.fldx_puestosallB){
      this.$refs.fldx_puestosallB.reset()
    }

    if (this.getHelperCat(6).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (6, null, false).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 6 finish ')
        testCatA = respx 
      })
    }
    if (this.getHelperCat(7).dataLoaded===false){ 
      var testCatB = false 
      await this.helpermix_getCatalog (7, null, false).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 7 finish ')
        testCatB = respx 
      })
    }
    await this._waitRequestProc(400)
    // this.helpermix_createMovil (6)
    // await this._waitRequestProc(200)
    // this.helpermix_createMovil (7)
    // await this._waitRequestProc(400)
    var operaVals = []
    var adminVals = []
    this.getHelperCat(6).dataCatalog.forEach( item => { 
      operaVals.push(item.id)
    })
    this.getHelperCat(7).dataCatalog.forEach( item => { 
      adminVals.push(item.id)
    })
    this.z_allowOpera = operaVals
    this.z_allowAdmin = adminVals
    this.$forceUpdate()

    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()
    
    if (this.$refs.fldx_puestosall){
      this.$refs.fldx_puestosall.reset()
    }
    if (this.$refs.fldx_puestosallB){
      this.$refs.fldx_puestosallB.reset()
    }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_puestos --   beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_puestos --   updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_puestos --   activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      await this._waitRequestProc(200)
      // ------------------
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()
      if (this.$refs.fldx_puestosall){
        this.$refs.fldx_puestosall.reset()
      }
      if (this.$refs.fldx_puestosallB){
        this.$refs.fldx_puestosallB.reset()
      }
      // ------------------
      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_puestos --   deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_puestos --   beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_puestos --   destroyed')
  }
}
</script>
